<script>
import CustomizationFields from './comp_CustomizationFields.vue'

export default {
	lang: 'customization',
	extends: CustomizationFields,
	created() {
		this.$assignDeep(this.customization.data, {
			name: null,
			nameb: null,
			gender: null,
		})
	},
	computed: {
		receiverInputLabel() {
			return this.config.receiverInputLabel || this.$lang('Nombre del nene/a')
		},
		giverInputLabel() {
			return this.config.giverInputLabel || this.$lang('Nombre de quién regala')
		},
	},
}
</script>

<template>
	<div>
		<Container class="d-flex align-start justify-center flex-wrap flex-sm-nowrap">
			<div
				class="mb-8 mb-sm-0 flex-grow-1"
				:class="{ w100: $vuetify.breakpoint.xs }"
				style="max-width: 500px"
			>
				<div class="label text-center text-sm-left">
					{{ receiverInputLabel }}
				</div>
				<TextField
					v-model="customization.data.name"
					:placeholder="$lang('Ingresa su nombre')"
					solo
					rounded
					:counter="config.nameMaxLen"
					:maxlength="config.nameMaxLen"
				/>
			</div>
			<div class="ml-sm-4" v-if="!config.giverGenderSelector">
				<div class="label d-none d-sm-block">&nbsp;</div>
				<CustomizationGenderSelector
					v-model="customization.data.gender"
					:mandatory="!!customization.data.gender"
					:error-message="uncatchedValidation['customization.data.gender']"
				/>
			</div>
		</Container>
		<Container class="d-flex align-start justify-center flex-wrap flex-sm-nowrap pt-10">
			<div class="flex-grow-1" :class="{ w100: $vuetify.breakpoint.xs }" style="max-width: 500px">
				<div class="label text-center text-sm-left">
					{{ giverInputLabel }}
				</div>
				<TextField
					v-model="customization.data.nameb"
					:placeholder="$lang('Ingresa el nombre')"
					solo
					rounded
					:counter="config.namebMaxLen"
					:maxlength="config.namebMaxLen"
				/>
			</div>
			<div class="ml-sm-4" v-if="config.giverGenderSelector">
				<div class="label d-none d-sm-block">&nbsp;</div>
				<CustomizationGenderSelector
					v-model="customization.data.gender"
					:mandatory="!!customization.data.gender"
					:error-message="uncatchedValidation['customization.data.gender']"
				/>
			</div>
		</Container>
	</div>
</template>

<script>
import { get } from 'vuex-pathify'
import { createPortals } from '@/__shared/utils/lib_utils'
import bgClouds from '@/site/@libromio/custom/assets/bg-clouds.png'
import bgCloudsMobile from '@/site/@libromio/custom/assets/bg-clouds-mobile.png'
import { generateProductSchema } from './product-schema'

const ProductPortals = createPortals(require.context('@/site', true, /portal_Product(-.+)?\.vue/))

export default {
	lang: 'shop',
	components: { ProductPortals },
	metaInfo() {
		let title = '...'
		let description = ''
		let script = []
		let link = []
		if (this.selectedVariant) {
			title = this.product.name
			description = this.product.info?.description || this.product.name
			script.push({
				innerHTML: JSON.stringify(generateProductSchema(this.product, this.selectedVariant), null, 2),
				type: 'application/ld+json',
				body: true,
			})
			link.push({ rel: 'canonical', href: window.location.href })
		}
		return {
			title,
			meta: [{ vmid: 'd', name: 'description', content: description }],
			script,
			link,
		}
	},
	data() {
		return {
			product: {},
			relatedProducts: [],
			loading: true,
			selectedVariantId: null,
			selectedVariant: null,
			customization: {},
			validation: {},
			bgClouds,
			bgCloudsMobile,
		}
	},
	computed: {
		routeParams: get('route@params'),
		routeVariantId: get('route@query.variant'),
		routeAddToCart: get('route@query.add-to-cart'),
		pageLoading() {
			return this.loading
		},
		bgImage() {
			if (this.loading) return ''
			return this.$vuetify.breakpoint.xs ? '' : this.bgClouds
		},
	},
	watch: {
		'routeParams.id'() {
			this.loadData()
		},
		selectedVariantId(value) {
			this.selectVariant(value)
		},
	},
	methods: {
		loadData() {
			let { id, urlName } = this.routeParams
			this.product = {}
			this.selectedVariant = null

			this.$shopApi.get({
				loading: (v) => (this.loading = v),
				url: `/catalog/product/${id}`,
				onSuccess: async ({ data, options }) => {
					let { product } = data
					if (!product) {
						//404
						return
					}

					if (product.urlName != urlName) {
						this.$router.replace({
							name: 'product',
							params: { id, urlName: product.urlName },
							query: this.$route.query,
						})
					}

					this.product = product
					this.relatedProducts = data.relatedProducts
					this.selectVariant(this.routeVariantId)
					this.autoAddToCart()
					this.$eventer.trigger('page:view')
					this.$prerenderReady()
				},
			})
		},
		selectVariant(variantId) {
			if (variantId && variantId == this.selectedVariant?.id) return

			let variant = null
			let isDefaultVariant = false
			if (variantId) variant = this.product.variants.find((variant) => variant.id == variantId)
			if (!variant) {
				isDefaultVariant = true
				variant = this.product.variants.find((variant) => variant.main)
				if (!variant) variant = this.product.variants[0]
			}

			if (this.routeVariantId != variant.id) {
				if (isDefaultVariant && this.routeVariantId) {
					this.$router.replace({
						query: { variant: undefined },
						params: { savePosition: true },
					})
				} else if (this.selectedVariant || this.routeVariantId) {
					this.$router.replace({
						query: { variant: variant.id },
						params: { savePosition: true },
					})
				}
			}

			this.selectedVariant = variant
			this.selectedVariantId = variant.id
			this.$eventer.trigger('product:view', {
				variant: this.selectedVariant,
				product: this.product,
			})
		},
		async autoAddToCart() {
			if (this.routeAddToCart != 1) return
			await this.$shopApi.post({
				url: '/cart/item',
				loading: false,
				data: {
					refType: 'product',
					refId: this.selectedVariant.id,
					qty: 1,
				},
				onSuccess: async ({ data }) => {
					this.$store.set('cart/order', data.order)
				},
			})
			await this.$router.push({ query: { ...this.$route.query, 'add-to-cart': undefined } })
			this.$store.set('shop/cartDrawer', true)
		},
	},
	created() {
		this.loadData()
	},
}
</script>

<template>
	<div
		v-if="loading"
		class="d-flex justify-center py-16 mt-8"
		:style="{ 'min-height': 'calc(100vh - 100px)' }"
	>
		<v-progress-circular :size="70" :width="7" color="primary" indeterminate />
	</div>
	<div v-else>
		<ProductPortals />
		<div class="py-sm-10 bg-container" :style="`background-image: url('${bgImage}')`">
			<Container :fluid="{ xs: true, sm: false }" class="pa-0">
				<v-row no-gutters>
					<v-col cols="12" md="7" class="pb-6 pb-md-0">
						<ProductImagesCarousel :images="product.images" />
						<portal-target name="Product.below-images" multiple />
					</v-col>

					<v-col cols="12" md="5">
						<div class="px-6 py-0 px-sm-0 pr-md-0 pl-md-4">
							<v-row no-gutters>
								<v-col cols="10" class="pr-3 pr-sm-0">
									<div class="font-4 font-sm-5 font-weight-bold white--text">
										{{ product.name }}
									</div>
								</v-col>
								<v-col cols="2" class="pl-sm-6">
									<div class="text-right d-flex flex-column align-center" style="position: absolute">
										<ProductWishlistToggler color="white" />
										<!-- <ProductShareToggler color="white" class="mt-2" /> -->
									</div>
								</v-col>
							</v-row>
							<v-row class="my-4">
								<v-col cols="12">
									<div style="position: relative">
										<VariantsSelector
											v-model="selectedVariantId"
											:product="product"
											key-class="white--text font-weight-bold text-uppercase mb-2"
											class="pb-3"
										>
											<template #valueButton="{ group, value, selected }">
												<Button
													@click="group.select(value)"
													:key="value"
													color="white"
													class="mr-3 mb-3"
													:class="{ 'primary--text font-weight-bold': selected }"
													:text="!selected"
													style="border: 1px solid white"
													:small="$vuetify.breakpoint.smAndUp"
												>
													{{ value }}
												</Button>
											</template>
										</VariantsSelector>
										<PriceLayout
											:prev-price="selectedVariant.pvPrice.prevPrice"
											:price="selectedVariant.pvPrice.price"
											:discount-pct="selectedVariant.pvPrice.discountPct"
											class="pb-3"
											price-class="white--text font-6 font-weight-bold"
											prev-price-class="grey--text text--lighten-1 font-2"
											:currency="selectedVariant.pvPrice.priceConfig.currency.sign"
										/>
										<div class="py-2 font-1">
											<div class="mb-1 font-0">
												<b class="white--text">SKU</b>
												<span class="grey--text text--lighten-3"> {{ selectedVariant.sku }}</span>
											</div>
											<ProductStockAvailabilityMessage
												:stock="selectedVariant.stock"
												class="py-1"
												color="white--text"
												without-icon
											/>
											<div
												class="d-flex align-center py-1"
												v-if="selectedVariant.type == 'digital'"
											>
												<v-icon class="mr-2">mdi-cloud-download</v-icon>
												{{ 'Producto digital descargable' | lang }}
											</div>
										</div>
										<portal-target name="Product.actions" multiple>
											<ProductActions
												:product="product"
												:variant="selectedVariant"
												:customization="customization"
												@validation="validation = $event"
												class="py-3"
											/>
										</portal-target>
										<portal-target name="Product.below-actions" multiple />
									</div>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
			</Container>
		</div>
		<portal-target name="Product.info" multiple />
		<div class="py-12" v-if="relatedProducts.length">
			<Container class="pb-4 font-3 font-weight-bold purple--text">
				{{ 'También te puede interesar' | lang }}
			</Container>
			<ProductsCarousel :products="relatedProducts" list-id="related-products" />
		</div>
		<portal-target name="Product.under" multiple />
		<Home-I-NewsLetter />
		<Home-J-SocialInfo class="mt-10 mb-15" />
	</div>
</template>

<style scoped>
.bg-container {
	background-color: var(--v-purple-base);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
}

.input_container {
	max-width: 10rem;
}

.input_container > input {
	max-width: 6rem;
}
</style>

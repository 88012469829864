<script>
export default {
	lang: 'customization',
	props: {
		genderModel: String,
		mandatory: Boolean,
		maleLabel: String,
		femaleLabel: String,
		errorMessage: String,
	},
	model: {
		prop: 'genderModel',
		event: 'updateModel',
	},
	data() {
		return {
			gender: this.genderModel,
		}
	},
	watch: {
		gender(value) {
			this.$emit('updateModel', value)
		},
		genderModel(value) {
			this.gender = value
		},
	},
}
</script>

<template>
	<div>
		<v-item-group class="d-flex" v-model="gender" :mandatory="mandatory">
			<v-item v-slot="{ active, toggle }" value="m">
				<div class="gender-btn rounded-pill ml-4 mr-2" :class="{ 'gender-btn--active': active }">
					<Button class="darken-2" color="purple" large @click="toggle">
						{{ maleLabel || $lang('Nene') }}
					</Button>
				</div>
			</v-item>
			<v-item v-slot="{ active, toggle }" value="f">
				<div class="gender-btn rounded-pill" :class="{ 'gender-btn--active': active }">
					<Button class="lighten-1" color="orange" large @click="toggle">
						{{ femaleLabel || $lang('Nena') }}
					</Button>
				</div>
			</v-item>
		</v-item-group>
		<div class="text-center mt-1" v-if="errorMessage">
			<span class="custom-error">{{ errorMessage }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		config: Object,
		validation: Object,
		uncatchedValidation: Object,
	},
	data() {
		return {
			customization: {
				data: {},
			},
		}
	},
	watch: {
		'customization.data': {
			deep: true,
			handler() {
				this.$emit('customization-data-changed', { ...this.customization.data })
			},
		},
	},
}
</script>

<template>
	<div></div>
</template>

<style lang="scss" scoped>
::v-deep .gender-btn {
	opacity: 0.8;
	&:hover {
		opacity: 1;
	}
	&--active {
		opacity: 1;
		.v-btn {
			border: 1px solid white !important;
			width: 150px;
			& ::v-deep .v-btn__content {
				font-weight: bold;
			}
		}
	}
}

::v-deep .v-input {
	.v-input__slot {
		background-color: white;
	}
	.v-input__control {
		position: relative;
	}
	.v-text-field__details {
		position: absolute;
		top: 100%;
		width: 100%;
	}
	.v-counter {
		color: white !important;
	}
}

.custom-error,
::v-deep .custom-error,
::v-deep .v-input .v-messages__message {
	color: white !important;
	background-color: var(--v-purple-base) !important;
	font-weight: bold;
	@include fontSize(0);
	padding: 4px 8px;
	border-radius: 4px;
	display: inline-block;
}

::v-deep .label {
	font-weight: bold;
	@include fontSize(1);
	color: white;
	white-space: nowrap;
	padding-bottom: 12px;
}
</style>

<script>
import ProductCard from '../product-card/comp_ProductCard.vue'
export default {
	lang: 'shop',
	extends: ProductCard,
	props: {
		checkedModel: Boolean,
		product: Object,
	},
	model: {
		prop: 'checkedModel',
		event: 'updateModel',
	},
	data() {
		return {
			checked: false,
		}
	},
	watch: {
		checked(value) {
			this.$emit('updateModel', value)
		},
	},
	methods: {
		onClick() {
			if (this.product.info.customizationKey) {
				const route = this.$router.resolve({
					name: 'product',
					params: { ...this.product },
				})
				window.open(route.href, '_blank')
			} else {
				this.checked = !this.checked
			}
		},
	},
}
</script>

<template>
	<v-card hover flat tile @click="onClick" class="elevation-1" height="100%" style="overflow: hidden">
		<div class="d-flex flex-column justify-space-between" style="height: 100%">
			<slot name="images" v-bind="{ mainImage, secondImage }">
				<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
			</slot>
			<div class="body-card">
				<div class="card-content d-flex flex-column py-3 px-4">
					<div class="line-clamp-2 font-1 purple--text font-weight-bold">
						{{ product.name }}
					</div>
					<v-spacer />
					<PriceLayout
						:prev-price="prevPrice"
						:price="price"
						:discount-pct="discountPct"
						:currency="currency"
					/>
					<div class="flex-grow-0 mt-3">
						<Button color="purple" block v-if="product.info.customizationKey">
							<v-icon small class="mr-2">mdi-pencil</v-icon> {{ 'Personalizar' | lang }}
						</Button>
						<Button color="purple" block v-else>
							<v-checkbox class="pt-0" v-model="checked" @click.stop="null" dark hide-details />
							{{ 'Agregar al carrito' | lang }}
						</Button>
					</div>
				</div>

				<div class="bubble" style="left: -30%"></div>
				<div class="bubble" style="left: 50%; transform: translateX(-50%)"></div>
				<div class="bubble" style="right: -30%"></div>
			</div>
		</div>
	</v-card>
</template>

<style scoped>
.checkbox-container {
	position: relative;
	z-index: 1;
}
.card-content {
	position: relative;
	z-index: 1;
	min-height: 150px;
}

.body-card {
	height: 100%;
	background-color: #eff7fa;
	position: relative;
}

.wishlist-position {
	position: absolute;
	top: 5px;
	right: 5px;
}

.bubble {
	position: absolute;
	background-image: linear-gradient(to bottom, #e0f0f5 5%, #eff7fa 95%);
	width: 60%;
	height: 60%;
	min-height: 8rem;
	border-radius: 50%;
	top: -2rem;
}
.v-input--checkbox {
	margin-top: 0;
}
</style>

<script>
export default {
	computed: {
		product() {
			return this.$parent.$parent.product
		},
		info() {
			return this.product.info || {}
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
	},
}
</script>

<template>
	<portal to="Product.info" v-if="info.templateKey == 'textImageList'">
		<Container class="py-12" :fluid="{ xs: true, sm: false }" :width="{ md: '75%' }">
			<v-row
				:class="{ inverted: item.inverted && !xs, 'inverted-xs': xs }"
				v-for="item of info.templateData.items"
				:key="item.id"
				:no-gutters="xs"
			>
				<v-col cols="12" sm="5" md="6" align-self="center">
					<div class="font-6 purple--text font-alt pt-5 pt-sm-0 px-8 px-sm-0">
						{{ item.title }}
					</div>
					<div class="pt-5 px-8 px-sm-0">
						{{ item.text }}
					</div>
				</v-col>
				<v-col cols="12" sm="7" md="6">
					<Media :src="item.image" width="100%" />
				</v-col>
			</v-row>
		</Container>
	</portal>
</template>

<style scoped>
.inverted {
	flex-direction: row-reverse;
}
.inverted-xs {
	flex-direction: column-reverse;
}
</style>

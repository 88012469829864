<script>
export default {
	lang: 'shop',
	props: {
		product: Object,
		variant: Object,
		ctaText: String,
		customization: Object,
	},
	data() {
		return {
			ctaIsLoading: false,
			qty: 1,
		}
	},
	computed: {
		outOfStock() {
			return this.variant.stock.availability == 'OutOfStock'
		},
		maxStockQty() {
			let { maxBuyableQty, infiniteQty, qty } = this.variant.stock
			if (maxBuyableQty >= 1) {
				if (infiniteQty) return maxBuyableQty
				else return Math.min(qty, maxBuyableQty)
			} else {
				if (infiniteQty) return 0
				else return qty
			}
		},
	},
	methods: {
		async ctaClicked() {
			return this.$shop.addToCart({
				productId: this.product.id,
				variantId: this.variant.id,
				qty: this.qty,
				sum: true,
				customization: this.customization,
				loading: (v) => (this.ctaIsLoading = v),
				onValidation: ({ validation }) => this.$emit('validation', validation),
			})
		},
	},
}
</script>

<template>
	<div>
		<QtyInput
			v-if="variant.type == 'physical' && !outOfStock"
			v-model="qty"
			:max-qty="maxStockQty"
			:unit-metric="product.unitMetric"
			:pack-metric="product.packMetric"
			:step="variant.metricFactor"
			style="width: 220px"
		/>
		<ProductStockQtyMessage
			v-if="variant.type == 'physical'"
			:stock="variant.stock"
			:metric="product.unitMetric"
			class="font-0 pt-2 grey--text text-center"
			style="width: 220px"
		/>
		<div class="mt-6">
			<Button
				:loading="ctaIsLoading"
				@click="ctaClicked"
				class="red lighten-1 white--text rounded-xl px-15 py-9"
				large
				:disabled="outOfStock"
			>
				{{ ctaText || $lang('Comprar') }}
			</Button>
		</div>
	</div>
</template>

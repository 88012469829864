var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-12"},[_c('Container',{staticClass:"d-flex align-start justify-center flex-wrap flex-sm-nowrap pt-10"},[_c('div',{staticClass:"mr-sm-4 mb-8 mb-sm-0 flex-grow-1",class:{ w100: _vm.$vuetify.breakpoint.xs },staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"label text-center text-sm-left"},[_vm._v(" "+_vm._s(_vm._f("lang")('Nombre del hermano/a mayor'))+" "),_c('b',[_vm._v("("+_vm._s(_vm._f("lang")('personaje principal'))+")")])]),_c('TextField',{attrs:{"placeholder":_vm.$lang('Ingresa su nombre'),"solo":"","rounded":"","counter":_vm.config.nameMaxLen,"maxlength":_vm.config.nameMaxLen},model:{value:(_vm.customization.data.name),callback:function ($$v) {_vm.$set(_vm.customization.data, "name", $$v)},expression:"customization.data.name"}})],1),_c('div',[_c('div',{staticClass:"label d-none d-sm-block"},[_vm._v(" ")]),_c('v-item-group',{staticClass:"d-flex",attrs:{"mandatory":!!_vm.customization.data.gender},model:{value:(_vm.customization.data.gender),callback:function ($$v) {_vm.$set(_vm.customization.data, "gender", $$v)},expression:"customization.data.gender"}},[_c('v-item',{attrs:{"value":"m"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"gender-btn rounded-pill ml-4 mr-2",class:{ 'gender-btn--active': active }},[_c('Button',{staticClass:"darken-2",attrs:{"color":"purple","large":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Nene'))+" ")])],1)]}}])}),_c('v-item',{attrs:{"value":"f"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"gender-btn rounded-pill",class:{ 'gender-btn--active': active }},[_c('Button',{staticClass:"lighten-1",attrs:{"color":"orange","large":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Nena'))+" ")])],1)]}}])})],1),(_vm.uncatchedValidation['customization.data.gender'])?_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.uncatchedValidation['customization.data.gender']))])]):_vm._e()],1)]),_c('Container',{staticClass:"d-flex align-start justify-center flex-wrap flex-sm-nowrap"},[_c('div',{staticClass:"mr-sm-4 mb-8 mb-sm-0 flex-grow-1",class:{ w100: _vm.$vuetify.breakpoint.xs },staticStyle:{"max-width":"500px"}},[_c('div',{staticClass:"label text-center text-sm-left"},[_vm._v(_vm._s(_vm._f("lang")('Nombre del nuevo/a bebe/a')))]),_c('TextField',{attrs:{"placeholder":_vm.$lang('Ingresa su nombre'),"solo":"","rounded":"","counter":_vm.config.namebMaxLen,"maxlength":_vm.config.namebMaxLen},model:{value:(_vm.customization.data.nameb),callback:function ($$v) {_vm.$set(_vm.customization.data, "nameb", $$v)},expression:"customization.data.nameb"}})],1),_c('div',[_c('div',{staticClass:"label d-none d-sm-block"},[_vm._v(" ")]),_c('v-item-group',{staticClass:"d-flex",attrs:{"mandatory":!!_vm.customization.data.genderb},model:{value:(_vm.customization.data.genderb),callback:function ($$v) {_vm.$set(_vm.customization.data, "genderb", $$v)},expression:"customization.data.genderb"}},[_c('v-item',{attrs:{"value":"m"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"gender-btn rounded-pill ml-4 mr-2",class:{ 'gender-btn--active': active }},[_c('Button',{staticClass:"darken-2",attrs:{"color":"purple","large":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Nene'))+" ")])],1)]}}])}),_c('v-item',{attrs:{"value":"f"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"gender-btn rounded-pill",class:{ 'gender-btn--active': active }},[_c('Button',{staticClass:"lighten-1",attrs:{"color":"orange","large":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm._f("lang")('Nena'))+" ")])],1)]}}])})],1),(_vm.uncatchedValidation['customization.data.genderb'])?_c('div',{staticClass:"text-center mt-1"},[_c('span',{staticClass:"custom-error"},[_vm._v(_vm._s(_vm.uncatchedValidation['customization.data.genderb']))])]):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
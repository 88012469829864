<script>
export default {
	props: {
		indexValue: Number,
		images: Array,
	},
	data() {
		return {
			showDialog: false,
			currentIndex: this.indexValue || 0,
			carouselHeight: 0,
		}
	},
	model: {
		prop: 'indexValue',
		event: 'updateIndexValue',
	},
	watch: {
		currentIndex(value) {
			this.$emit('updateIndexValue', value)
			if (this.$refs.thumbsCarousel?.initialized) {
				let { camera, control } = this.$refs.thumbsCarousel.flicking
				let newPos = camera.range.min + camera.rangeDiff * (value / (this.images.length - 1))
				let diff = Math.abs(newPos - camera.position)
				let duration = diff > 40 ? 3500 : 1000
				control.moveToPosition(newPos, duration)
			}
		},
		indexValue(value) {
			this.currentIndex = value
		},
	},
	methods: {
		moveTo(index, duration) {
			this.$refs.carousel.moveTo(index, duration)
		},
		afterResize() {
			this.carouselHeight = this.$refs.carousel.$children[0].$el.clientHeight
			this.$refs.thumbsCarousel?.init()
		},
	},
	computed: {
		thumbsWidth() {
			return 62
		},
		thumbsVerticalMargin() {
			return 12
		},
	},
}
</script>

<template>
	<div>
		<div class="d-flex">
			<div class="flex-shrink-1 mr-4" v-if="$vuetify.breakpoint.smAndUp">
				<Carousel
					ref="thumbsCarousel"
					:items="images"
					:options="{
						itemWrapperClass: 'pb-3',
						itemWrapperStyle: `height: ${thumbsWidth + thumbsVerticalMargin}px`,
						viewportStyle: `height: ${carouselHeight}px;`,
					}"
					:flicking-options="{
						align: 'prev',
						bound: true,
						horizontal: false,
						autoInit: false,
						moveType: 'freeScroll',
						disableOnInit: true,
					}"
				>
					<template #panel="{ item: image, i }">
						<ProductImagesThumb
							@click="moveTo(i)"
							@mouseover="moveTo(i, 0)"
							:src="image.thumb"
							:active="currentIndex == i"
							:width="thumbsWidth"
						/>
					</template>
				</Carousel>
			</div>
			<Carousel
				ref="carousel"
				class="images-carousel flex-grow-1 py-4 py-sm-0"
				v-model="currentIndex"
				:items="images"
				:options="{
					duration: showDialog ? 0 : 300,
					itemsPadding: { xs: 1, sm: 1, md: 6 },
					panelsPerView: { xs: 1.1, sm: 1.1, md: 1 },
				}"
				:flicking-options="{
					bound: true,
				}"
				@after-resize="afterResize"
			>
				<template #panel="{ item: image }">
					<div class="mx-sm-2">
						<Media
							:src="image.medium"
							scale-down
							width="100%"
							aspect-ratio="1"
							loader
							@click="showDialog = true"
							img-class="rounded-lg"
						/>
					</div>
				</template>
			</Carousel>
		</div>
		<ProductImagesDialog
			v-model="currentIndex"
			:images="images"
			:show-dialog="showDialog"
			@closeDialog="showDialog = false"
		/>
	</div>
</template>

<script>
	import baseProductWishlistToggler from '@/site/shop/catalog/common/comp_ProductWishlistToggler'
	export default {
		extends: baseProductWishlistToggler,
		props: {
			color: String,
		},
	}
</script>

<template>
	<Button
		@click.stop.prevent="toggleWishlist"
		:color="color"
		class="rounded-circle"
		depressed
		height="48"
		width="48"
		min-width="48"
	>
		<v-icon color="red lighten-2" large>{{ icon }}</v-icon>
	</Button>
</template>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"flex-shrink-1 mr-4"},[_c('Carousel',{ref:"thumbsCarousel",attrs:{"items":_vm.images,"options":{
					itemWrapperClass: 'pb-3',
					itemWrapperStyle: ("height: " + (_vm.thumbsWidth + _vm.thumbsVerticalMargin) + "px"),
					viewportStyle: ("height: " + _vm.carouselHeight + "px;"),
				},"flicking-options":{
					align: 'prev',
					bound: true,
					horizontal: false,
					autoInit: false,
					moveType: 'freeScroll',
					disableOnInit: true,
				}},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
				var image = ref.item;
				var i = ref.i;
return [_c('ProductImagesThumb',{attrs:{"src":image.thumb,"active":_vm.currentIndex == i,"width":_vm.thumbsWidth},on:{"click":function($event){return _vm.moveTo(i)},"mouseover":function($event){return _vm.moveTo(i, 0)}}})]}}],null,false,3677721638)})],1):_vm._e(),_c('Carousel',{ref:"carousel",staticClass:"images-carousel flex-grow-1 py-4 py-sm-0",attrs:{"items":_vm.images,"options":{
				duration: _vm.showDialog ? 0 : 300,
				itemsPadding: { xs: 1, sm: 1, md: 6 },
				panelsPerView: { xs: 1.1, sm: 1.1, md: 1 },
			},"flicking-options":{
				bound: true,
			}},on:{"after-resize":_vm.afterResize},scopedSlots:_vm._u([{key:"panel",fn:function(ref){
			var image = ref.item;
return [_c('div',{staticClass:"mx-sm-2"},[_c('Media',{attrs:{"src":image.medium,"scale-down":"","width":"100%","aspect-ratio":"1","loader":"","img-class":"rounded-lg"},on:{"click":function($event){_vm.showDialog = true}}})],1)]}}]),model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}})],1),_c('ProductImagesDialog',{attrs:{"images":_vm.images,"show-dialog":_vm.showDialog},on:{"closeDialog":function($event){_vm.showDialog = false}},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
export default {
	lang: 'common',
	props: {
		text: String,
		maxChars: Number,
		expandBtnText: String,
		collapseBtnText: String,
	},
	data() {
		return {
			showFull: false,
		}
	},
	computed: {
		cText() {
			return this.showFull || !this.maxChars ? this.text : this.text.substring(0, this.maxChars)
		},
		showBtn() {
			return this.maxChars && this.text.length > this.maxChars
		},
		btnText() {
			return this.showFull
				? this.collapseBtnText || this.$lang('Cerrar')
				: this.expandBtnText || this.$lang('Leer Más')
		},
	},
}
</script>

<template>
	<div class="preline">
		{{ cText }}<span v-if="showBtn && !showFull">...</span>
		<div v-if="showBtn" class="d-flex justify-end">
			<Button @click="showFull = !showFull" color="primary">
				<v-icon class="mr-1">{{ showFull ? 'mdi-minus' : 'mdi-plus' }}</v-icon
				>{{ btnText }}
			</Button>
		</div>
	</div>
</template>

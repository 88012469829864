<script>
export default {
	lang: 'product',
	computed: {
		product() {
			return this.$parent.$parent.product
		},
		showTemplate() {
			return this.product.info?.templateKey == 'descriptionAndAttrs' && this.both
		},
		description() {
			return this.product.info?.templateData?.description || this.product.info?.description
		},
		attrs() {
			return this.product.attrs || []
		},
		both() {
			return this.description && this.attrs.length
		},
	},
}
</script>

<template>
	<portal to="Product.info" v-if="showTemplate">
		<Container class="mt-8 mb-16 pa-8">
			<v-row class="flex-md-nowrap flex-column flex-md-row">
				<v-col cols="12" :md="both ? 7 : 12" v-if="description" class="flex-shrink-md-1">
					<div class="font-2 font-weight-bold pb-4 purple--text">
						{{ 'Descripción' | lang }}
					</div>
					<TruncatedText :text="description" :max-chars="$vuetify.breakpoint.xs ? 300 : 0" />
				</v-col>
				<v-divider :vertical="$vuetify.breakpoint.mdAndUp" class="mx-md-4 my-4 my-md-0" v-if="both" />
				<v-col cols="12" :md="both ? 5 : 12" v-if="attrs.length">
					<div class="font-2 font-weight-bold pb-4 purple--text">
						{{ 'Características' | lang }}
					</div>
					<v-row>
						<v-col cols="6" md="4" v-for="(item, i) of attrs" :key="i">
							<div class="font-weight-bold text-uppercase font-0">
								{{ item.attrKey.k }}
							</div>
							<div class="pt-1">
								{{ item.v }}
							</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</Container>
	</portal>
</template>

<style scoped>
.container {
	border: 1px solid #ccc;
	border-radius: 12px;
}
</style>

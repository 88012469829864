<script>
import CustomizationFields from './comp_CustomizationFields.vue'

export default {
	lang: 'customization',
	extends: CustomizationFields,
	computed: {
		dateItems() {
			return [
				{ text: this.$lang('Día'), value: null },
				...Array.from(Array(31)).map((n, i) => ({ text: i + 1, value: i + 1 })),
			]
		},
		monthItems() {
			// prettier-ignore
			let months = [this.$lang('Enero'), this.$lang('Febrero'), this.$lang('Marzo'), this.$lang('Abril'), this.$lang('Mayo'), this.$lang('Junio'), this.$lang('Julio'), this.$lang('Agosto'), this.$lang('Septiembre'), this.$lang('Octubre'), this.$lang('Noviembre'), this.$lang('Diciembre')]
			return [
				{ text: this.$lang('Mes'), value: null },
				...months.map((name, i) => ({ text: name, value: i + 1 })),
			]
		},
	},
	created() {
		this.$assignDeep(this.customization.data, {
			name: null,
			gender: null,
			age: null,
			date: null,
			monthNum: null,
		})
	},
}
</script>

<template>
	<div>
		<Container class="d-flex align-start justify-center flex-wrap flex-sm-nowrap">
			<div
				class="mr-sm-4 mb-8 mb-sm-0 flex-grow-1"
				:class="{ w100: $vuetify.breakpoint.xs }"
				style="max-width: 500px"
			>
				<div class="label text-center text-sm-left">
					{{ 'Nombre del nene/a' | lang }}
				</div>
				<TextField
					v-model="customization.data.name"
					:placeholder="$lang('Ingresa su nombre')"
					solo
					rounded
					:counter="config.nameMaxLen"
					:maxlength="config.nameMaxLen"
				/>
			</div>

			<div>
				<div class="label d-none d-sm-block">&nbsp;</div>
				<v-item-group
					class="d-flex"
					v-model="customization.data.gender"
					:mandatory="!!customization.data.gender"
				>
					<v-item v-slot="{ active, toggle }" value="m">
						<div class="gender-btn rounded-pill ml-4 mr-2" :class="{ 'gender-btn--active': active }">
							<Button class="darken-2" color="purple" large @click="toggle">
								{{ 'Nene' | lang }}
							</Button>
						</div>
					</v-item>
					<v-item v-slot="{ active, toggle }" value="f">
						<div class="gender-btn rounded-pill" :class="{ 'gender-btn--active': active }">
							<Button class="lighten-1" color="orange" large @click="toggle">
								{{ 'Nena' | lang }}
							</Button>
						</div>
					</v-item>
				</v-item-group>
				<div class="text-center mt-1" v-if="uncatchedValidation['customization.data.gender']">
					<span class="custom-error">{{ uncatchedValidation['customization.data.gender'] }}</span>
				</div>
			</div>
		</Container>
		<Container class="d-flex align-start justify-center flex-wrap flex-sm-nowrap pt-10">
			<div
				class="mr-sm-4 mb-8 mb-md-0 text-center text-sm-left"
				:class="{ w100: $vuetify.breakpoint.xs }"
				style="width: 350px"
			>
				<div class="label">{{ '¿Cuántos años va a cumplir?' | lang }}</div>
				<TextField
					type="number"
					v-model="customization.data.age"
					:placeholder="$lang('Ingresa los años')"
					solo
					rounded
					class=""
				/>
			</div>
			<div>
				<div class="label text-center text-sm-left">{{ 'Día y Mes de cumpleaños' | lang }}</div>
				<div class="d-flex align-center">
					<Select
						v-model="customization.data.date"
						solo
						rounded
						:items="dateItems"
						class="mr-4"
						style="min-width: 100px; max-width: 130px"
					/>
					<Select
						v-model="customization.data.monthNum"
						solo
						rounded
						:items="monthItems"
						style="min-width: 190px; max-width: 190px"
					/>
				</div>
			</div>
		</Container>
	</div>
</template>

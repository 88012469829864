<script>
export default {
	lang: 'customization',
	data() {
		return {
			cartLoading: false,
			preview: false,
			previewSrc: null,
		}
	},
	computed: {
		productView() {
			return this.$parent.$parent
		},
		info() {
			return this.productView.product.info || {}
		},
		customization() {
			return this.productView.customization
		},
		customizationKey() {
			return this.info?.customizationKey
		},
		customizationFieldsComponent() {
			return `CustomizationFields-${this.customizationKey.replaceAll('_', '-')}`
		},
		config() {
			return this.info?.customizationData
		},
		validCustomizationKeys() {
			return ['gender_name', 'gender_name_brother', 'gender_name_giver', 'gender_name_age_birthday']
		},
		showPortals() {
			return this.customizationKey && this.validCustomizationKeys.includes(this.customizationKey)
		},
	},
	watch: {
		showPortals(value) {
			if (!value) return
			this.initializeCustomization()
		},
		customization: {
			deep: true,
			handler() {
				this.previewSrc = null
			},
		},
	},
	methods: {
		initializeCustomization() {
			this.productView.customization = {}
			this.$assignDeep(this.productView.customization, {
				data: {},
				key: this.customizationKey,
			})
		},
		generatePreview() {
			this.preview = true
			if (this.previewSrc && this.previewSrc !== 'error') return
			this.previewSrc = null
			this.$shopApi.post({
				url: '/book-preview',
				data: {
					customization: this.customization,
					variantId: this.productView.selectedVariant.id,
				},
				onValidation: ({ validation }) => {
					this.productView.validation = validation
					if (Object.keys(validation).length) {
						this.preview = false
					}
				},
				onSuccess: ({ data }) => {
					let src = this.$shopApi.makeUrl('/book-preview', { query: { src: data.previewSrc } })
					let img = new Image()
					img.onload = () => {
						this.previewSrc = src
					}
					img.onerror = () => {
						this.previewSrc = 'error'
					}
					img.src = src
				},
			})
		},
		addToCart() {
			return this.$shop.addToCart({
				productId: this.productView.selectedVariant.productId,
				variantId: this.productView.selectedVariant.id,
				qty: 1,
				customization: this.customization,
				loading: (v) => (this.cartLoading = v),
				onValidation: ({ validation }) => (this.productView.validation = validation),
				onSuccess: () => {
					this.preview = false
				},
			})
		},
		onCustomizationDataChanged(data) {
			this.$assignDeep(this.productView.customization, { data })
		},
	},
	mounted() {
		if (this.showPortals) this.initializeCustomization()
	},
}
</script>

<template>
	<div style="display: none" v-if="showPortals">
		<portal to="Product.actions">
			<div class="pt-8 d-none d-sm-block">
				<Button color="yellow" x-large @click="$vuetify.goTo('.customization-panel', { offset: 30 })">
					{{ 'Comprar' | lang }}
				</Button>
			</div>
		</portal>
		<portal to="Product.below-actions">
			<Validator :validation="productView.validation" v-slot="{ 'customization.key': keyError }">
				<div v-if="keyError" class="error--text">{{ keyError }}</div>
			</Validator>
		</portal>
		<portal to="Product.info" :order="-1">
			<Validator :validation="productView.validation" v-slot="uncatched">
				<div class="purple lighten-3 customization-panel">
					<div class="purple white--text py-3">
						<Container class="d-flex align-center font-alt justify-center">
							<div class="step-number yellow font-1 font-weight-bold">1</div>
							<div class="font-2 font-weight-bold">
								{{ 'Personalizá el libro' | lang }}
							</div>
						</Container>
					</div>
					<component
						class="py-12"
						:is="customizationFieldsComponent"
						@customization-data-changed="onCustomizationDataChanged"
						:config="config"
						:validation="productView.validation"
						:uncatched-validation="uncatched"
					/>
					<div class="purple white--text py-3">
						<Container class="d-flex align-center font-alt justify-center">
							<div class="step-number yellow font-1 font-weight-bold">2</div>
							<div class="font-2 font-weight-bold">
								{{ 'Previsualizá y agregá al Carrito' | lang }}
							</div>
						</Container>
					</div>
					<Container class="d-flex align-center justify-center py-8 flex-wrap flex-md-nowrap">
						<Button color="red" large @click="generatePreview" class="mr-sm-4 mb-4 mb-sm-0">
							<v-icon class="mr-1">mdi-eye</v-icon> {{ 'Previsualizar' | lang }}
						</Button>
						<Button color="success" large :loading="cartLoading" @click="addToCart">
							<v-icon class="mr-1">mdi-cart-outline</v-icon> {{ 'Agregar al carrito' | lang }}
						</Button>
					</Container>
				</div>
			</Validator>
			<CustomizationPreview
				v-model="preview"
				:src="previewSrc"
				:cart-loading="cartLoading"
				@add-to-cart="addToCart"
			/>
		</portal>
	</div>
</template>

<style scoped>
.step-number {
	width: 35px;
	height: 35px;
	line-height: 35px;
	margin-right: 16px;
	text-align: center;
	border-radius: 100%;
}
</style>

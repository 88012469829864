<script>
import CustomizationFields from './comp_CustomizationFields.vue'

export default {
	lang: 'customization',
	extends: CustomizationFields,
	created() {
		this.$assignDeep(this.customization.data, {
			name: null,
			gender: null,
		})
	},
}
</script>

<template>
	<Container class="d-flex align-start justify-center flex-wrap flex-sm-nowrap">
		<div
			class="mr-sm-4 mb-8 mb-sm-0 flex-grow-1"
			:class="{ w100: $vuetify.breakpoint.xs }"
			style="max-width: 500px"
		>
			<div class="label text-center text-sm-left">{{ 'Nombre del nene/a' | lang }}</div>
			<TextField
				v-model="customization.data.name"
				:placeholder="$lang('Ingresa su nombre')"
				solo
				rounded
				:counter="config.nameMaxLen"
				:maxlength="config.nameMaxLen"
			/>
		</div>

		<div>
			<div class="label d-none d-sm-block">&nbsp;</div>
			<v-item-group
				class="d-flex"
				v-model="customization.data.gender"
				:mandatory="!!customization.data.gender"
			>
				<v-item v-slot="{ active, toggle }" value="m">
					<div class="gender-btn rounded-pill ml-4 mr-2" :class="{ 'gender-btn--active': active }">
						<Button class="darken-2" color="purple" large @click="toggle">
							{{ 'Nene' | lang }}
						</Button>
					</div>
				</v-item>
				<v-item v-slot="{ active, toggle }" value="f">
					<div class="gender-btn rounded-pill" :class="{ 'gender-btn--active': active }">
						<Button class="lighten-1" color="orange" large @click="toggle">
							{{ 'Nena' | lang }}
						</Button>
					</div>
				</v-item>
			</v-item-group>
			<div class="text-center mt-1" v-if="uncatchedValidation['customization.data.gender']">
				<span class="custom-error">{{ uncatchedValidation['customization.data.gender'] }}</span>
			</div>
		</div>
	</Container>
</template>

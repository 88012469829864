<script>
import Vue from 'vue'
export default {
	lang: 'shop',
	props: {
		stock: Object,
		withoutIcon: Boolean,
		color: String,
	},
	computed: {
		availability() {
			return this.stock.availability
		},
		preOrderDays() {
			return this.stock.deferredDelivery
		},
		qty() {
			return this.stock.qty
		},
		infiniteQty() {
			return this.stock.infiniteQty
		},
		icon() {
			switch (this.availability) {
				case 'OutOfStock':
					return 'mdi-cancel'
				case 'PreSale':
					return 'mdi-clock'
				case 'PreOrder':
					return 'mdi-clock'
				case 'InStock':
				default:
					return 'mdi-check'
			}
		},
	},
	methods: {
		filter(name, ...args) {
			return Vue.filter(name)(...args)
		},
	},
}
</script>

<template>
	<div class="d-flex align-center" :class="color">
		<v-icon v-if="!withoutIcon" class="mr-2">{{ icon }}</v-icon>
		<span v-if="availability == 'OutOfStock'">
			{{ 'No disponible en stock' | lang }}
		</span>
		<span v-else-if="availability == 'PreSale'">
			{{ 'Disponible a partir del {date}' | lang({ date: filter('date', stock.availabilityDate) }) }}
		</span>
		<span v-else-if="availability == 'PreOrder'">
			{{ 'Disponible en {days} días hábiles a partir de la compra' | lang({ days: preOrderDays }) }}
		</span>
		<span v-else> {{ 'Disponible en stock' | lang }} </span>
	</div>
</template>

<script>
export default {
	props: {
		color: String,
	},
}
</script>
<template>
	<Button :color="color" fab depressed>
		<v-icon color="red lighten-2" large>mdi-share-variant</v-icon>
	</Button>
</template>
